import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  Params,
  Router,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Subscription, filter, map, skip, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginAccData } from '../interfaces/login-acc-data';
import { LoginToken } from '../interfaces/login-token';
import { ResetPasswordRequest } from '../interfaces/reset-password-request.model';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root',
})
export class LoginService implements OnDestroy {
  private noUser = new BehaviorSubject<boolean>(true);
  private clientLoggedIn = new BehaviorSubject<boolean>(false);
  private companyLoggedIn = new BehaviorSubject<boolean>(false);
  private registering = new BehaviorSubject<boolean>(false);

  noUser$ = this.noUser.asObservable();
  clientLoggedIn$ = this.clientLoggedIn.asObservable();
  companyLoggedIn$ = this.companyLoggedIn.asObservable();
  registering$ = this.registering.asObservable();

  subscriptionRouter: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute
  ) {}

  acitvateClient() {
    this.noUser.next(false);
    this.clientLoggedIn.next(true);
    this.companyLoggedIn.next(false);
    this.registering.next(false);
  }

  activateCompany() {
    this.noUser.next(false);
    this.clientLoggedIn.next(false);
    this.companyLoggedIn.next(true);
    this.registering.next(false);
  }

  registeringUser() {
    this.noUser.next(false);
    this.clientLoggedIn.next(false);
    this.companyLoggedIn.next(false);
    this.registering.next(true);
  }

  clientLogIn() {
    if (localStorage.getItem('candidateExists') === null) {
      console.log('null');
      return;
    } else {
      if (localStorage.getItem('candidateExists') === 'true') {
        this.acitvateClient();
        // setTimeout(() => {
        //   if (this.router.url === '/') {
        //     this.router.navigate(['/client/settings']);
        //   }
        // }, 500);

        // this.router.navigate(['/client/settings']);
        // this.router.navigate(['client/notifications']);
        // this.router.navigate(['/reg']);
        // this.router.navigate(['/client/activities']);
        // this.router.navigate(['client/crm']);
        // this.router.navigate(['/client/statistics']);
        // this.registeringUser();
      } else if (localStorage.getItem('candidateExists') === 'false') {
        this.router.navigate(['/reg']);
        this.registeringUser();
      } else {
        this.router.navigate(['']);
        this.logOut();
      }
    }
  }

  companyLogIn() {
    if (localStorage.getItem('candidateExists') === null) {
      return;
    } else {
      if (localStorage.getItem('candidateExists') === 'true') {
        this.activateCompany();
        // this.router.navigate(['company/settings']);
        // this.router.navigate(['company/activities']);
        // this.router.navigate(['company/reg']);
        this.router.navigate(['company/candidates']);
        // this.router.navigate([
        //   'company/candidates/40769627-5bf6-4640-8d6c-1ac131e72f5b',
        // ]);
        // this.router.navigate(['company/notifications']);
        // this.router.navigate(['/company/statistics']);
      } else if (localStorage.getItem('candidateExists') === 'false') {
        this.router.navigate(['/company/reg']);
        this.registeringUser();
      } else {
        this.router.navigate(['']);
        this.logOut();
      }
    }
  }

  logOut() {
    this.noUser.next(true);
    this.clientLoggedIn.next(false);
    this.companyLoggedIn.next(false);
    this.registering.next(false);
    localStorage.removeItem('authToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userId');
    localStorage.removeItem('candidateId');
    localStorage.removeItem('regData');
    localStorage.removeItem('candidateExists');
    localStorage.removeItem('recruiterId');
    localStorage.removeItem('companyId');
    localStorage.removeItem('userFirstName');
    localStorage.removeItem('userLastName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('isRegruterConfirmed');
    this.router.navigate(['']);
  }

  onLogin(loginData: LoginAccData) {
    return this.http.post<LoginToken>(
      `${environment.API_JOOBZZ}${environment.API_POST_LOGIN}`,
      loginData,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  getUserType() {
    let userRole = '';
    if (localStorage.getItem('userRole')) {
      userRole = localStorage.getItem('userRole') as string;
    }
    return userRole;
  }

  refreshToken() {
    return this.http.post<LoginToken>(
      `${environment.API_JOOBZZ}${environment.API_POST_REFRESH_TOKEN}`,
      '',
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      }
    );
  }

  resetPassword(data: ResetPasswordRequest) {
    return this.http.post(
      `${environment.API_JOOBZZ}${environment.API_POST_PASSWORD_RESET}`,
      data
    );
  }

  ngOnDestroy(): void {
    this.noUser.unsubscribe();
    this.clientLoggedIn.unsubscribe();
    this.companyLoggedIn.unsubscribe();
    this.subscriptionRouter.unsubscribe();
  }
}
