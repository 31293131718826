import { CookieConfirmService } from './cookie-confirm.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Lang } from './../interfaces/lang.model';
import { Injectable, OnDestroy } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription, map, retry, finalize } from 'rxjs';
import { DropdownDataService } from './dropdown-data.service';
import { DateAdapter } from '@angular/material/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserLanguage } from '../interfaces/user-language.model';
import { CandidateNotificationsService } from './candidate-notifications.service';
import { CompanyNotificationsService } from './company-notifications.service';
import { CandidateInfoService } from './candidate-info.service';
import { CompanyInfoService } from './company-info.service';

const LANG_COOKIE = '_joobzz_lang';
const DEFAULT_LANG = 'rs';

@Injectable({
  providedIn: 'root',
})
export class LangSelectService implements OnDestroy {
  settingLang = false;
  activeLangSubscription = new Subscription();
  private languages: Lang[] = [
    {
      value: 'en',
      display_value: 'ENG',
      img_url: './assets/img/eng-flag.svg',
    },
    {
      value: 'rs',
      display_value: 'SRB',
      img_url: './assets/img/sr-flag.svg',
    },
  ];
  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private cookieConfirmService: CookieConfirmService,
    private dateAdapter: DateAdapter<Date>,
    private dropDownDataService: DropdownDataService,
    private http: HttpClient,
    private candidateNotificationService: CandidateNotificationsService,
    private companyNotificationsService: CompanyNotificationsService,
    private companyInfoService: CompanyInfoService,
    private candidateInfoService: CandidateInfoService
  ) {
    this.getActiveLang();
  }

  getActiveLang() {
    this.activeLangSubscription = this.translate.onLangChange
      .pipe(
        map((e) => {
          if (e.lang === 'rs') {
            this.dateAdapter.setLocale('sr-Latn-RS');
            return 'sr-Latn-RS';
          } else {
            this.dateAdapter.setLocale('en-GB');
            return 'en-US';
          }
        })
      )
      .subscribe((res) => {
        localStorage.setItem('appLang', res);
        // console.log(res);
        this.dropDownDataService.refreshCitizenship();
        this.dropDownDataService.refreshRegions();
        this.dropDownDataService.refreshPlaces();
        this.dropDownDataService.refreshWorkPermits();
        this.dropDownDataService.refreshDrivingLiceneces();
        this.dropDownDataService.refreshTypeOfEmployments();
        this.dropDownDataService.refreshJobTitles();
        this.dropDownDataService.refreshIndustries();
        this.dropDownDataService.refreshLanguages();
        this.dropDownDataService.refreshSkills();
        this.dropDownDataService.refreshItTools();
        this.dropDownDataService.refreshDegrees();
        this.dropDownDataService.refreshEducationalInstitutions();
        this.dropDownDataService.refreshCertificates();
        this.dropDownDataService.refreshitKnowledgeLevels();
        this.dropDownDataService.refreshFunctionsWithinCompany();
        this.dropDownDataService.refreshSubscriptions();
        this.dropDownDataService.refreshWorkModels();
        if (localStorage.getItem('userRole') === 'Candidate') {
          this.candidateNotificationService.getAllCandidateNotifications();
          this.candidateInfoService.getCandidateOverview();
        }
        if (localStorage.getItem('userRole') === 'Company') {
          this.companyNotificationsService.getAllCompanyNotifications();
          this.companyInfoService.getRecruiterOverview();
        }
      });
  }

  getLanguages() {
    return this.languages;
  }

  getInitialLanguage() {
    if (this.cookieService.check(LANG_COOKIE)) {
      return this.cookieService.get(LANG_COOKIE);
    }
    return DEFAULT_LANG;
  }

  setInitalLanguage() {
    if (this.cookieService.check(LANG_COOKIE)) {
      this.translate.use(this.cookieService.get(LANG_COOKIE));
    } else {
      this.cookieService.set(LANG_COOKIE, DEFAULT_LANG, 400);
      this.translate.use(DEFAULT_LANG);
    }
  }

  private langKeyModify(lng: 'en' | 'rs') {
    if (lng === 'rs') {
      return 'sr-Latn-RS';
    } else {
      return 'en-US';
    }
  }

  setActiveLang(ln: string) {
    this.translate.use(ln);
    if (ln !== this.cookieService.get(LANG_COOKIE)) {
      this.setLanguageRemote(this.setLangId(ln));
    }

    if (this.cookieConfirmService.cookiesAccepted()) {
      this.cookieService.set(LANG_COOKIE, ln, 400, '/client');
      this.cookieService.set(LANG_COOKIE, ln, 400, '/');
      this.cookieService.set(LANG_COOKIE, ln, 400, '/company');
      this.cookieService.set(LANG_COOKIE, ln, 400, '/admin');
      this.cookieService.set(LANG_COOKIE, ln, 400, '/reg');
    }
  }

  private setLangId(ln: string) {
    if (ln === 'en') {
      return 1;
    }
    if (ln === 'rs') {
      return 2;
    }
    return 0;
  }

  getLanguageRemote(userId: string) {
    const params = new HttpParams().set('id', userId);

    return this.http
      .get<number>(
        `${environment.API_JOOBZZ}${environment.API_GET_USER_LANGUAGE}`,
        { params }
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  setLanguageRemote(languageId: number, userId?: string) {
    if (typeof userId === 'undefined') {
      userId = localStorage.getItem('userId') || '';
    }
    this.settingLang = true;
    if (userId !== '') {
      this.http
        .post<UserLanguage>(
          `${environment.API_JOOBZZ}${environment.API_POST_USER_LANGUAGE}`,
          {
            userId: userId,
            languageId: languageId,
          }
        )
        .pipe(retry({ count: 2, delay: 700 }))
        .subscribe({
          next: (res) => {
            // console.log(res);
          },
          error: (err) => {
            // console.log(err);
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.activeLangSubscription.unsubscribe();
  }
}
