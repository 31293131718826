import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidatorsService } from 'src/app/services/validators.service';
import { usernameValidator } from 'src/app/validators/email-exists.validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-name-mail',
  templateUrl: './name-mail.component.html',
  styleUrls: ['./name-mail.component.scss'],
})
export class NameMailComponent implements OnInit {
  blogUrl = environment.APP_BLOG_ROUTE;
  hide = true;
  signInStepTwo = this.formBuilder.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: [
      '',
      {
        validators: [Validators.required, Validators.email],
        asyncValidators: [usernameValidator(this.validatorsService)],
      },
    ],
    privacy: ['', [Validators.required, Validators.requiredTrue]],
  });

  get firstName() {
    return this.signInStepTwo.controls.firstName;
  }

  get lastName() {
    return this.signInStepTwo.controls.lastName;
  }

  get email() {
    return this.signInStepTwo.controls.email;
  }

  get privacy() {
    return this.signInStepTwo.controls.privacy;
  }

  constructor(
    private formBuilder: FormBuilder,
    private validatorsService: ValidatorsService
  ) {}
  ngOnInit(): void {
    // this.signInStepTwo.valueChanges.subscribe((res) => {
    // console.log(this.signInStepTwo);
    // console.log(this.privacy);
    // });
    // console.log(this.privacy);
  }
}
