import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { companyGuard } from './guards/company.guard';
import { candidateGuard } from './guards/candidate.guard';
import { candidateAndCompanyGuard } from './guards/candidate-and-company.guard';
import { adminGuard } from './guards/admin.guard';
import { adminAndCompanyGuard } from './guards/admin-and-company.guard';
import { homePageGuard } from './guards/home-page.guard';

const routes: Routes = [
  {
    path: 'about-us',
    loadChildren: () =>
      import('./pages/about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'o-nama',
    loadChildren: () =>
      import('./pages/about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'user-agreement',
    loadChildren: () =>
      import('./pages/user-agreement/user-agreement.module').then(
        (m) => m.UserAgreementModule
      ),
  },
  {
    path: 'refund-policy',
    loadChildren: () =>
      import('./pages/refund-policy/refund-policy.module').then(
        (m) => m.RefundPolicyModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'uslovi-koristenja',
    loadChildren: () =>
      import('./pages/tearms-of-use/tearms-of-use.module').then(
        (m) => m.TearmsOfUseModule
      ),
  },
  {
    path: 'impressum',
    loadChildren: () =>
      import('./pages/impressum/impressum.module').then(
        (m) => m.ImpressumModule
      ),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./pages/blog-list/blog-list.module').then(
        (m) => m.BlogListModule
      ),
  },
  {
    path: 'kandidati',
    loadChildren: () =>
      import(
        './pages/candidate-presentation/candidate-presentation.module'
      ).then((m) => m.CandidatePresentationModule),
  },
  {
    path: 'candidates',
    loadChildren: () =>
      import(
        './pages/candidate-presentation/candidate-presentation.module'
      ).then((m) => m.CandidatePresentationModule),
  },
  {
    path: 'poslodavci',
    loadChildren: () =>
      import('./pages/company-presentation/company-presentation.module').then(
        (m) => m.CompanyPresentationModule
      ),
  },
  {
    path: 'companies',
    loadChildren: () =>
      import('./pages/company-presentation/company-presentation.module').then(
        (m) => m.CompanyPresentationModule
      ),
  },
  {
    path: 'aplikacija',
    loadChildren: () =>
      import('./pages/app-presentation/app-presentation.module').then(
        (m) => m.AppPresentationModule
      ),
  },
  {
    path: 'application',
    loadChildren: () =>
      import('./pages/app-presentation/app-presentation.module').then(
        (m) => m.AppPresentationModule
      ),
  },
  {
    path: 'kontakt',
    loadChildren: () =>
      import('./pages/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./pages/contact/contact.module').then((m) => m.ContactModule),
  },

  {
    path: 'istaknuti-poslodavci',
    loadChildren: () =>
      import('./pages/employers-list/employers-list.module').then(
        (m) => m.EmployersListModule
      ),
  },
  {
    path: 'featured-companies',
    loadChildren: () =>
      import('./pages/employers-list/employers-list.module').then(
        (m) => m.EmployersListModule
      ),
  },

  {
    path: 'reg',
    canActivate: [candidateGuard],
    loadChildren: () =>
      import('./pages/client/reg/reg.module').then((m) => m.RegModule),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./pages/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'blog/:postId',
    loadChildren: () =>
      import('./pages/blog-single/blog-single.module').then(
        (m) => m.BlogSingleModule
      ),
  },
  {
    path: 'istaknuti-poslodavci/:employerId',
    loadChildren: () =>
      import('./pages/employers-single/employers-single.module').then(
        (m) => m.EmployersSingleModule
      ),
  },
  {
    path: 'featured-companies/:employerId',
    loadChildren: () =>
      import('./pages/employers-single/employers-single.module').then(
        (m) => m.EmployersSingleModule
      ),
  },
  {
    path: 'blog/tag/:tagId',
    loadChildren: () =>
      import('./pages/blog-tag-list/blog-tag-list.module').then(
        (m) => m.BlogTagListModule
      ),
  },
  {
    path: 'reg/verify',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'client/settings',
    canActivate: [candidateGuard],
    loadChildren: () =>
      import('./pages/client/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'client/notifications',
    canActivate: [candidateGuard],
    loadChildren: () =>
      import('./pages/client/notifications/notifications.module').then(
        (m) => m.NotificationsModule
      ),
  },
  {
    path: 'client/activities',
    canActivate: [candidateGuard],
    loadChildren: () =>
      import('./pages/client/activities/activities.module').then(
        (m) => m.ActivitiesModule
      ),
  },
  {
    path: 'client/statistics',
    canActivate: [candidateGuard],
    loadChildren: () =>
      import('./pages/client/statistics/statistics.module').then(
        (m) => m.StatisticsModule
      ),
  },
  {
    path: 'client/crm',
    canActivate: [candidateAndCompanyGuard],
    loadChildren: () =>
      import('./pages/client/crm/crm.module').then((m) => m.CrmModule),
  },
  {
    path: 'company/candidates',
    canActivate: [companyGuard],
    loadChildren: () =>
      import('./pages/company/candidates/candidates.module').then(
        (m) => m.CandidatesModule
      ),
  },
  {
    path: 'company/candidates/:userId',
    canActivate: [adminAndCompanyGuard],
    loadChildren: () =>
      import('./pages/company/candidate-single/candidate-single.module').then(
        (m) => m.CandidateSingleModule
      ),
  },
  {
    path: 'company/settings',
    canActivate: [companyGuard],
    loadChildren: () =>
      import('./pages/company/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'company/notifications',
    canActivate: [companyGuard],
    loadChildren: () =>
      import('./pages/company/notifications/notifications.module').then(
        (m) => m.NotificationsModule
      ),
  },
  {
    path: 'company/activities',
    canActivate: [companyGuard],
    loadChildren: () =>
      import('./pages/company/activities/activities.module').then(
        (m) => m.ActivitiesModule
      ),
  },
  {
    path: 'company/statistics',
    canActivate: [companyGuard],
    loadChildren: () =>
      import('./pages/company/statistics/statistics.module').then(
        (m) => m.StatisticsModule
      ),
  },
  {
    path: 'company/reg',
    canActivate: [companyGuard],
    loadChildren: () =>
      import('./pages/company/reg/reg.module').then((m) => m.RegModule),
  },
  {
    path: 'admin/client/candidates',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/client/candidates/candidates.module').then(
        (m) => m.CandidatesModule
      ),
  },
  {
    path: 'admin/client/trafic',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/client/trafic/trafic.module').then(
        (m) => m.TraficModule
      ),
  },
  {
    path: 'admin/client/payments',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/client/payments/payments.module').then(
        (m) => m.PaymentsModule
      ),
  },
  {
    path: 'admin/client/payments-candidate',
    canActivate: [adminGuard],
    loadChildren: () =>
      import(
        './pages/admin/client/payments-candidate/payments-candidate.module'
      ).then((m) => m.PaymentsCandidateModule),
  },
  {
    path: 'admin/client/settings',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/client/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'admin/company/companies',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/company/companies/companies.module').then(
        (m) => m.CompaniesModule
      ),
  },

  {
    path: 'admin/company/trafic',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/company/trafic/trafic.module').then(
        (m) => m.TraficModule
      ),
  },
  {
    path: 'admin/company/status',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/company/status/status.module').then(
        (m) => m.StatusModule
      ),
  },
  {
    path: 'admin/company/settings',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/company/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'admin/company/confirmation',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/company/confirmation/confirmation.module').then(
        (m) => m.ConfirmationModule
      ),
  },
  {
    path: 'admin/center/settings',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/center/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'admin/center/crm',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/center/crm/crm.module').then((m) => m.CrmModule),
  },

  {
    path: 'admin/blogs',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/blog/blog-list/blog-list.module').then(
        (m) => m.BlogListModule
      ),
  },
  {
    path: 'admin/blogs/blog-single-edit',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/blog/blog-edit/blog-edit.module').then(
        (m) => m.BlogEditModule
      ),
  },
  {
    path: 'admin/blogs/blog-single-edit/:blogId',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/blog/blog-edit/blog-edit.module').then(
        (m) => m.BlogEditModule
      ),
  },
  {
    path: 'admin/employers-list',
    canActivate: [adminGuard],
    loadChildren: () =>
      import(
        './pages/admin/employers/employers-list/employers-list.module'
      ).then((m) => m.EmployersListModule),
  },
  {
    path: 'admin/employers-list/employer',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/employers/employer-edit/employer-edit.module').then(
        (m) => m.EmployerEditModule
      ),
  },
  {
    path: 'admin/employers-list/employer/:employerId',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/employers/employer-edit/employer-edit.module').then(
        (m) => m.EmployerEditModule
      ),
  },
  {
    path: 'admin/blog/tag-list',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/blog/tag-list/tag-list.module').then(
        (m) => m.TagListModule
      ),
  },
  {
    path: 'admin/blog/tag-list/tag',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/blog/tag-edit/tag-edit.module').then(
        (m) => m.TagEditModule
      ),
  },
  {
    path: 'admin/blog/tag-list/tag/:tagID',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/blog/tag-edit/tag-edit.module').then(
        (m) => m.TagEditModule
      ),
  },
  {
    path: 'admin/testimonials',
    canActivate: [adminGuard],
    loadChildren: () =>
      import('./pages/admin/testimonials/testimonials.module').then(
        (m) => m.TestimonialsModule
      ),
  },
  {
    path: 'admin/testimonials/edit-testimonial',
    canActivate: [adminGuard],
    loadChildren: () =>
      import(
        './pages/admin/testimonials/edit-tesitimonial/edit-tesitimonial.module'
      ).then((m) => m.EditTesitimonialModule),
  },
  {
    path: 'admin/testimonials/edit-testimonial/:testimonialId',
    canActivate: [adminGuard],
    loadChildren: () =>
      import(
        './pages/admin/testimonials/edit-tesitimonial/edit-tesitimonial.module'
      ).then((m) => m.EditTesitimonialModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },

  {
    path: '**',
    loadChildren: () =>
      import('./pages/error404/error404.module').then((m) => m.Error404Module),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // scrollPositionRestoration: 'enabled',
      // onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
