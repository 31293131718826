export const environment = {
  production: false,
  API_JOOBZZ: 'https://testapi.joobzz.rs/api',
  API_POST_REGISTER: '/auth/reg',
  API_POST_RESETPASSWORD: '/account/resetpassword',
  API_POST_LOGIN: '/auth/login',
  API_GET_COUNTRIES: '/countries',
  API_GET_REGIONS: '/regions',
  API_GET_PLACES: '/places',
  API_GET_WORK_PERMITS: '/workPermits',
  API_GET_DRIVING_LICENSES: '/CategoryDrivingLicences',
  API_POST_USER_EXISTS: '/auth/userExists',
  API_GET_EMPLOYMENT_TYPE: '/typeOfEmployments',
  API_GET_JOB_TITLES: '/jobTitles',
  API_GET_INDUSTRIES: '/industries',
  API_GET_LANGUAGES: '/languages',
  API_GET_SKILLS: '/skillSets',
  API_GET_IT_TOOLS: '/itTools',
  API_GET_DEGREES: '/degrees',
  API_GET_EDUCATIONAL_INSTITUTIONS: '/educations',
  API_GET_CERTIFICATIONS: '/certificates',
  API_GET_IT_KNOWLEDGE_LEVELS: '/ItKnowledgeLevels',
  API_GET_FUNCTION_WITHIN_COMPANIES: '/FunctionWithinCompanies',
  API_POST_CANDIDATE_ALL: '/candidates',
  API_GET_CANDIDATE_ALL: '/candidates',
  API_GET_CANDIDATE_PER_USER: '/candidates/perUser',
  API_GET_CANDIDATE_OVERVIEW: '/candidates/get-candidate-overview',
  API_PUT_CANDIDATE_CERTIFICATES: '/candidateCertificates/updateList',
  API_PUT_CANDIDATE: '/Candidates',
  API_DELETE_CANDIDATE_CERTIFICATE: '/candidateCertificates',
  API_PUT_CANDIDATE_SKILLS: '/CandidateSkillSets/updatelist',
  API_DELETE_CANDIDATE_SKILL: '/CandidateSkillSets',
  API_PUT_CANDIDATE_JOBS: '/CandidateJobs/updatelist',
  API_DELETE_CANDIDATE_JOB: '/CandidateJobs',
  API_PUT_WORK_EXPERIENCES: '/CandidateWorkExperiences/updatelist',
  API_DELETE_WORK_EXPERIENCE: '/CandidateWorkExperiences',
  API_PUT_CANDIDATE_REGIONS: '/CandidateEmploymentStatusRegions/updatelist',
  API_DELETE_CANDIDATE_REGION: '/CandidateEmploymentStatusRegions',
  API_PUT_CANDIDATE_EDUCATIONS: '/CandidateEducations/updatelist',
  API_DELETE_CANDIDATE_EDUCATIONS: '/CandidateEducations',
  API_PUT_CANDIDATE_LANGUAGES: '/CandidateLanguageLevels/updatelist',
  API_DELETE_CANDIDATE_LANGUAGE: '/CandidateLanguageLevels',
  API_PUT_CANDIDATE_IT_SKILLS: '/CandidateItSkillSets/updatelist',
  API_DELETE_CANDIDATE_IT_SKILL: '/CandidateItSkillSets',
  API_GET_PROFILE_COMPLEATNES: '/Candidates/calculate-profile-completeness',
  API_PUT_CANDIDATE_CITIZENSHIPS: '/CandidateCitizenships/updatelist',
  API_DELETE_CANDIDATE_CITIZENSHIP: '/CandidateCitizenships',
  API_PUT_CANDIDATE_DRIVING_LICENCES: '/CandidateDrivingLicences/updatelist',
  API_DELETE_CANDIDATE_DRIVINIG_LICENCE: '/CandidateDrivingLicences',
  API_POST_UPLOAD_CANDIDATE_DOCUMENTS: '/CandidateDocuments',
  API_GET_DOCUMENTS_FILENAME: '/CandidateDocuments',
  API_POST_DOCUMENT_DELETE: '/CandidateDocuments/delete',
  API_GET_CANDIDATE_PRESENTATION_DATA: '/Candidates/overviewRegToUser',
  API_GET_CANDIDATE_SUBSCRIPTTION_PLANS: '/SubscriptionPlanTypes',
  API_POST_CANDIDATE_SUBSCRIPTION_PLAN: '/CandidateSubscriptions',
  API_POST_STRIPE_TOKEN: '/CandidateSubscriptions/subscription/payment',
  API_PUT_CANDIDATE_PROFILE_STATUS: '/Candidates/SetCandidateProfileStatus',
  API_GET_CANDIDATE_CV_PDF: '/PdfDocuments/CvInPdfDownload',
  API_DELETE_CANDIDATE: '/candidates',
  API_POST_REMOVE_USER: '/auth/deleteUser',
  API_POST_REFRESH_TOKEN: '/Auth/refresh-token',
  API_POST_PASSWORD_RESET: '/Auth/resetPasswordRequest',
  API_GET_CANDIDATE_MONTHLY_STATISTICS: '/Candidates/summary-monthly',
  API_GET_CANDIDATE_HALF_MONTH_STATISTICS: '/Candidates/summary-half-monthly',
  API_POST_UNSUBSCRIBE: '/CandidateSubscriptions/subscription/cancel',
  API_GET_REGIONS_BY_CONTRY: '/Regions/regionsbycountry',
  API_GET_PLACES_BY_REGION: '/Places/placesbyregionid',
  API_GET_IS_SUBSCRIPTION_CANCELED_CHECK:
    '/CandidateSubscriptions/subscription/status',
  API_GET_COUPON_VALID: '/CandidateSubscriptions/coupon/exists',
  API_GET_COUPON_INFO: '/CandidateSubscriptions/getcouponinfo',
  API_GET_USER_LANGUAGE: '/Auth/get-language',
  API_POST_USER_LANGUAGE: '/Auth/set-language',
  API_GET_WORK_MODELS: '/WorkModes',
  API_GET_CANDIDATE_PAYMENT_SUCCESS_DETAILS:
    '/CandidateSubscriptions/PaymentSuccessStatus',
  API_GET_CANDIDATE_PAYMENT_ERROR:
    '/SubscriptionPaymentErrorStatusLogs/PaymentErrorStatus',

  API_POST_COMPANY: '/RecruiterPersonalInformations',
  API_GET_COMPANY: '/Companies',
  API_GET_RECRUITER: '/RecruiterPersonalInformations',
  API_GET_RECRUITER_COMPANY_DATA: '/RecruiterPersonalInformations/details',
  API_GET_RECRUITER_COMPLEATNES:
    '/RecruiterPersonalInformations/calculate-profile-completeness',
  API_PUT_UPDATE_RECRUITER_DATA: '/RecruiterPersonalInformations',
  API_PUT_UPDATE_COMPANY_DATA: '/Companies',
  API_GET_RECRUITER_OVERVIEW:
    '/RecruiterPersonalInformations/get-recruiter-overview',
  API_GET_COMPANY_NOTIFICATIONS: '/get-enabled-notification',
  API_POST_COMPANY_NOTIFICATIONS_CHANGE: '/change-enabled-notification',
  API_GET_COMPANY_ALL_NOTIFICATIONS: '/get-notification',
  API_POST_SET_NOTIFICATION_STATUS: '/set-notification-status',
  API_GET_SET_NOTIFICATION_READ: '/set-notification-read',
  API_POST_POST_COMPANY_SEARCH: '/SearchCandidate',
  API_GET_COMPANY_SEARCH_RESULT: '/SearchCandidate/search-candidate',
  API_GET_LAST_SEARCH: '/SearchCandidate',
  API_PUT_SEARCH_UPDATE: '/SearchCandidate',
  API_GET_COMPANY_SEND_VIEW_REQUEST: '/send-view-request',
  API_POST_CREATE_NOTIFICATION: '/create-notification',
  API_DELETE_RECRUITER: '/RecruiterPersonalInformations',
  API_POST_CONFIRM_RECRUITER_EMAIL: '/Auth/confirmRegruterEmail',
  API_GET_RECRUITER_MONTHLY_STATISTICS:
    '/RecruiterPersonalInformations/summary-monthly',
  API_GET_RECRUITER_HALF_MONTH_STATISTICS:
    '/RecruiterPersonalInformations/summary-half-monthly',
  API_GET_RECRUITER_ACTIVITIES:
    '/RecruiterPersonalInformations/get-recruiter-activities',
  API_GET_RECRUITER_APPROVED_STATE:
    '/RecruiterPersonalInformations/is-recruiter-approved',
  API_POST_PROFILE_VIEWED_BY_RECRUITER:
    '/CandidateProfileViewedByRecruiter/view-profile',

  API_GET_ADMIN_NOT_CONFIRMED_RECRUITERS:
    '/AdminDashboard/get-not-confrimed-recruiter',
  API_GET_ADMIN_APPROVE_USER: '/AdminDashboard/approve',
  API_GET_ADMIN_ALL_CANDIDATES: '/AdminDashboard/get-all-candidates',
  API_GET_ADMIN_ALL_RECRUITERS: '/AdminDashboard/get-all-recruiters',
  API_GET_ADMIN_CANDIDATES_NOTIFICATIONS:
    '/AdminDashboard/get-all-notification-candidates',
  API_GET_ADMIN_RECRUITERS_NOTIFICATIONS:
    '/AdminDashboard/get-all-notification-recruiters',
  API_GET_CANDIDATE_PAYMENTS: '/AdminDashboard/get-all-candidates-payments',
  API_POST_ADMIN_SET_FREE_PREMIUM: '/CandidateSubscriptions/admin-premium',

  API_POST_CRM_TICKET: '/CrmTicketing',
  API_GET_ALL_CRM_TICKETS: '/CrmTicketing/get-all-crm-tickets',
  API_DELETE_TICKET: '/CrmTicketing',
  API_PUT_SET_TICKET_STATE: '/CrmTicketing',

  API_GET_ALL_PRESENTATION_COMPANIES: '/CompanyPosts/get-all-CompanyPosts',
  API_GET_COMPANIES_LIST: '/CompanyPosts',
  API_GET_COMPANY_BY_SLUG: '/CompanyPosts/slug',
  API_POST_NEW_COMPANY: '/CompanyPosts',
  API_GET_SINGLE_COMPANY: '/CompanyPosts',
  API_GET_COMPANY_ALL_TRANSLATIONS: '/CompanyPosts/get-all-translation-by-id',
  API_PUT_UPDATE_COMPANY: '/CompanyPosts',
  API_DELETE_COMPANY: '/CompanyPosts',

  API_GET_TAGS_LIST: '/Tags/get-all',
  API_GET_ALL_TAGS_LIST_PAGINATION: '/Tags/get-all-tags',
  API_POST_NEW_TAG: '/Tags',
  API_GET_SINGLE_TAG: '/Tags',
  API_GET_SINGLE_TAG_ALL_TRANSLATIONS: '/Tags/get-all-translation-by-id',
  API_PUT_UPDATE_TAG: '/Tags',
  API_DELETE_TAG: '/Tags',

  API_GET_TESTEMONIAL_LIST: '/Testimonials/get-all',
  API_GET_TESTEMONIAL_LIST_PAGINATION: '/Testimonials/get-all-Testimonials',
  API_GET_TESTEMONIAL: '/Testimonials',
  API_GET_TESTEMONIAL_ALL_TRANSLATIONS:
    '/Testimonials/get-all-translation-by-id',
  API_POST_TESTEMONIAL: '/Testimonials',
  API_PUT_TESTEMONIAL: '/Testimonials',
  API_DELETE_TESTEMONIAL: '/Testimonials',

  API_GET_BLOG_LIST: '/Posts/get-all',
  API_GET_BLOG_LIST_PAGINATION: '/Posts/get-all-posts',
  API_GET_BLOG_LIST_BY_TAG_ID: '/Posts/get-by-tagid',
  API_GET_BLOG_BY_SLUG: '/Posts/slug',
  API_GET_BLOG: '/Posts',
  API_GET_BLOG_ALL_TRANSLATIONS: '/Posts/get-all-translation-by-id',
  API_POST_BLOG: '/Posts',
  API_PUT_BLOG: '/Posts',
  API_DELETE_BLOG: '/Posts',

  APP_ADMIN_ROUTE: 'https://testadmin.joobzz.rs',
  APP_BLOG_ROUTE: 'https://testblog.joobzz.rs',
};
