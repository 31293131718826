import { LangSelectService } from 'src/app/services/lang-select.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import { SignupResetPasData } from 'src/app/interfaces/signup-reset-pas-data.model';
import { SignUpService } from 'src/app/services/sign-up.service';
import { openLoginDialog } from '../login-dialog/login-dialog.component';
import { Router } from '@angular/router';
import { SignupResetPassReturnData } from 'src/app/interfaces/signup-reset-pass-return-data.model';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-sign-up-dialog',
  templateUrl: './sign-up-dialog.component.html',
  styleUrls: ['./sign-up-dialog.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class SignUpDialogComponent implements OnInit {
  blogUrl = environment.APP_BLOG_ROUTE;
  activeStep = 0;
  firstName = '';
  lastName = '';

  isLinear = false;

  setPassResponsData = {} as SignupResetPassReturnData;
  langId: 1 | 2 = 1;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private signupService: SignUpService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: SignupResetPasData,
    private langSelectService: LangSelectService
  ) {}

  ngOnInit(): void {
    this.activeStep = this.data.step;
    // this.activeStep = 1;
    this.setLang();
  }

  setLang() {
    if (this.langSelectService.getInitialLanguage() === 'en') {
      this.langId = 1;
    } else if (this.langSelectService.getInitialLanguage() === 'rs') {
      this.langId = 2;
    }
  }

  onSignIn(accType: any, nameMail: any) {
    if (nameMail.value.privacy) {
      this.signupService.onSignup({
        type: Number(accType.value.type),
        firstName: nameMail.value.firstName,
        lastName: nameMail.value.lastName,
        email: nameMail.value.email,
        languageId: this.langId,
      });
      // console.log('lang', this.langId);
    }
  }

  onPasswordSet(accPass: any) {
    // console.log(accPass);
    this.signupService
      .onSetPassword({
        password: accPass.pass,
        email: this.data.userName,
        token: encodeURIComponent(this.data.token as string),
      })
      .subscribe((res) => {
        this.setPassResponsData = res;
        // console.log(res);
        this.router.navigate(['']);
      });
  }
  toLogin() {
    openLoginDialog(this.dialog);
  }
}

export function singnUpDialog(dialog: MatDialog, data?: SignupResetPasData) {
  const config = new MatDialogConfig();

  config.disableClose = true;
  config.autoFocus = true;
  config.maxWidth = '573';
  config.height = '700px';
  config.backdropClass = 'customDialogBackdrop';
  config.panelClass = ['customDialogOverlay', 'signUpOverlay'];

  config.data = { ...data };

  const dialogRef = dialog.open(SignUpDialogComponent, config);

  return dialogRef.afterClosed();
}
