import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  finalize,
  of,
  retry,
  switchMap,
  take,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { CandidateJobs } from '../interfaces/candidate-jobs.model';
import { CandidateOverview } from '../interfaces/candidate-overview.model';
import { CandidatePostAllReturn } from '../interfaces/candidate-post-all-return.model';
import { CandidatePostAll } from '../interfaces/candidate-post-all.model';
import { CandidatePresentationData } from '../interfaces/candidate-presentation-data.model';
import { CandidateProfileStatus } from '../interfaces/candidate-profile-status.model';
import { CandidateRegions } from '../interfaces/candidate-regions.model';
import { CandidateSubscription } from '../interfaces/candidate-subscription.model';
import { Candidate } from '../interfaces/candidate.model';
import { CertificateEditData } from '../interfaces/certificate-edit-data.model';
import { CitizenshipResponse } from '../interfaces/citizenship-response.model';
import { DocumentGetData } from '../interfaces/document-get-data.model';
import { DrivingLicenseResponse } from '../interfaces/driving-license-response.model';
import { Education } from '../interfaces/education.model';
import { LanguageForm } from '../interfaces/language-form.model';
import { SkillIt } from '../interfaces/skill-it.model';
import { SkillSet } from '../interfaces/skill-set.model';
import { SubscriptionStripePost } from '../interfaces/subscription-stripe-post.model';
import { WorkExperience } from '../interfaces/work-experience.model';
import { Statistics } from '../interfaces/statistics.model';
import { IsCanceledSubscription } from '../interfaces/is-canceled-subscription.model';
import { CouponInfo } from '../interfaces/coupon-info.model';
import { PaymentResponseAllSecure } from '../interfaces/payment-response-all-secure.model';
import { PaymentSuccessDetailsResult } from '../interfaces/payment-success-details-result.model';
import { PaymentErrorDetailsResult } from '../interfaces/payment-error-details-result.model';

@Injectable({
  providedIn: 'root',
})
export class CandidateInfoService {
  private candidateProfileCompleteness = new BehaviorSubject<number>(0);
  private candidateAllData = new BehaviorSubject<CandidatePostAllReturn>(
    {} as CandidatePostAllReturn
  );
  private candidateOverview = new BehaviorSubject<CandidateOverview>(
    {} as CandidateOverview
  );

  private candidateUploadedDocuments = new BehaviorSubject<DocumentGetData[]>(
    [] as DocumentGetData[]
  );

  private candidateAllPresentationData =
    new Subject<CandidatePresentationData>();

  private candidateMonthlyStatistics = new BehaviorSubject<Statistics>(
    {} as Statistics
  );

  private candidateHalfMonthStatistics = new BehaviorSubject<Statistics>(
    {} as Statistics
  );

  candidateProfileCompleteness$ =
    this.candidateProfileCompleteness.asObservable();
  candidateAllData$ = this.candidateAllData.asObservable();
  candidateOverview$ = this.candidateOverview.asObservable();
  candidateUploadedDocuments$ = this.candidateUploadedDocuments.asObservable();
  candidateAllPresentationData$ =
    this.candidateAllPresentationData.asObservable();
  candidateMonthlyStatistics$ = this.candidateMonthlyStatistics.asObservable();
  candidateHalfMonthStatistics$ =
    this.candidateHalfMonthStatistics.asObservable();

  constructor(private http: HttpClient) {}

  saveAll(data: CandidatePostAll) {
    return this.http
      .post<CandidatePostAll>(
        `${environment.API_JOOBZZ}${environment.API_POST_CANDIDATE_ALL}`,
        data
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  getCandidateOverview() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<CandidateOverview>(
        `${environment.API_JOOBZZ}${environment.API_GET_CANDIDATE_OVERVIEW}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.candidateOverview.next(res);
        },
      });
  }
  getCandidateAllData(userId?: string | null) {
    if (userId === undefined) {
      userId = localStorage.getItem('userId')?.toString();
    }
    return this.http
      .get<CandidatePostAllReturn>(
        `${environment.API_JOOBZZ}${environment.API_GET_CANDIDATE_PER_USER}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.candidateAllData.next(res);
        },
      });
  }

  private _getCandidateAllData(userId: string) {
    return this.http
      .get<CandidatePostAllReturn>(
        `${environment.API_JOOBZZ}${environment.API_GET_CANDIDATE_PER_USER}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  getCandidateAllPresentationData(userId: string) {
    return this.http
      .get<CandidatePresentationData>(
        `${environment.API_JOOBZZ}${environment.API_GET_CANDIDATE_PRESENTATION_DATA}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.candidateAllPresentationData.next(res);
        },
      });
  }

  getCandidateProfilePercentage() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<number>(
        `${environment.API_JOOBZZ}${environment.API_GET_PROFILE_COMPLEATNES}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.candidateProfileCompleteness.next(res);
        },
      });
  }

  editCandidate(data: Candidate, id: number) {
    return this.http
      .put(
        `${environment.API_JOOBZZ}${environment.API_PUT_CANDIDATE}/${id}`,
        data
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  editCertificates(data: CertificateEditData[]) {
    return this.http
      .put(
        `${environment.API_JOOBZZ}${environment.API_PUT_CANDIDATE_CERTIFICATES}`,
        data
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  removeCertificate(id: number) {
    return this.http
      .delete(
        `${environment.API_JOOBZZ}${environment.API_DELETE_CANDIDATE_CERTIFICATE}/${id}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  editSkillSets(data: SkillSet[]) {
    return this.http
      .put(
        `${environment.API_JOOBZZ}${environment.API_PUT_CANDIDATE_SKILLS}`,
        data
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  removeSkillSet(id: number) {
    return this.http
      .delete(
        `${environment.API_JOOBZZ}${environment.API_DELETE_CANDIDATE_SKILL}/${id}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  editJobs(data: CandidateJobs[]) {
    return this.http
      .put(
        `${environment.API_JOOBZZ}${environment.API_PUT_CANDIDATE_JOBS}`,
        data
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  removeJob(id: number) {
    return this.http
      .delete(
        `${environment.API_JOOBZZ}${environment.API_DELETE_CANDIDATE_JOB}/${id}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  editWorkExperiences(data: WorkExperience[]) {
    return this.http
      .put(
        `${environment.API_JOOBZZ}${environment.API_PUT_WORK_EXPERIENCES}`,
        data
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  removeWorkExperience(id: number) {
    return this.http
      .delete(
        `${environment.API_JOOBZZ}${environment.API_DELETE_WORK_EXPERIENCE}/${id}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  editCandidateRegions(data: CandidateRegions[]) {
    return this.http
      .put(
        `${environment.API_JOOBZZ}${environment.API_PUT_CANDIDATE_REGIONS}`,
        data
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  removeCandidateRegion(id: number) {
    return this.http
      .delete(
        `${environment.API_JOOBZZ}${environment.API_DELETE_CANDIDATE_REGION}/${id}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  editCandidateEducations(data: Education[]) {
    return this.http
      .put(
        `${environment.API_JOOBZZ}${environment.API_PUT_CANDIDATE_EDUCATIONS}`,
        data
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  removeCandidateEducation(id: number) {
    return this.http
      .delete(
        `${environment.API_JOOBZZ}${environment.API_DELETE_CANDIDATE_EDUCATIONS}/${id}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  editCandidateLanguages(data: LanguageForm[]) {
    return this.http
      .put(
        `${environment.API_JOOBZZ}${environment.API_PUT_CANDIDATE_LANGUAGES}`,
        data
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  removeCandidateLanguage(id: number) {
    return this.http
      .delete(
        `${environment.API_JOOBZZ}${environment.API_DELETE_CANDIDATE_LANGUAGE}/${id}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  editCandidateItSkills(data: SkillIt[]) {
    return this.http
      .put(
        `${environment.API_JOOBZZ}${environment.API_PUT_CANDIDATE_IT_SKILLS}`,
        data
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  removeCandidateItSkill(id: number) {
    return this.http
      .delete(
        `${environment.API_JOOBZZ}${environment.API_DELETE_CANDIDATE_IT_SKILL}/${id}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  editCandidateCitizenships(data: CitizenshipResponse[]) {
    return this.http
      .put(
        `${environment.API_JOOBZZ}${environment.API_PUT_CANDIDATE_CITIZENSHIPS}`,
        data
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  removeCandidateCitizenship(id: number) {
    return this.http
      .delete(
        `${environment.API_JOOBZZ}${environment.API_DELETE_CANDIDATE_CITIZENSHIP}/${id}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  editCandidateDrivingLicences(data: DrivingLicenseResponse[]) {
    return this.http
      .put(
        `${environment.API_JOOBZZ}${environment.API_PUT_CANDIDATE_DRIVING_LICENCES}`,
        data
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  removeCandidateDrivingLicence(id: number) {
    return this.http
      .delete(
        `${environment.API_JOOBZZ}${environment.API_DELETE_CANDIDATE_DRIVINIG_LICENCE}/${id}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .pipe(take(1));
  }

  uploadCandidateFiles(documents: FormData) {
    return this.http.post<DocumentGetData[]>(
      `${environment.API_JOOBZZ}${environment.API_POST_UPLOAD_CANDIDATE_DOCUMENTS}`,
      documents,
      { reportProgress: true, observe: 'events' }
    );
  }

  candidateGetFiles(candidateId?: number) {
    if (candidateId === undefined) {
      candidateId = Number(localStorage.getItem('candidateId'));
    }
    return this.http
      .get<DocumentGetData[]>(
        `${environment.API_JOOBZZ}${environment.API_GET_DOCUMENTS_FILENAME}/${candidateId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.candidateUploadedDocuments.next(res);
        },
      });
  }

  deleteCandidateFile(file: DocumentGetData[]) {
    return this.http
      .post<DocumentGetData[]>(
        `${environment.API_JOOBZZ}${environment.API_POST_DOCUMENT_DELETE}`,
        file
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  setCandidateSubscriptionFree() {
    const obj = {} as CandidateSubscription;
    obj.candidateId = Number(localStorage.getItem('candidateId'));
    obj.candidateSubscriptionId = 0;
    obj.subscriptionPlanTypeId = 4;
    this.http
      .post<CandidateSubscription>(
        `${environment.API_JOOBZZ}${environment.API_POST_CANDIDATE_SUBSCRIPTION_PLAN}`,
        obj
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          console.log(res);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  grantFreePremium(candidateId: number) {
    return this.http
      .post(
        `${environment.API_JOOBZZ}${environment.API_POST_ADMIN_SET_FREE_PREMIUM}`,
        {
          subscriptionPlanTypeId: 5,
          candidateId: candidateId,
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      )

      .subscribe({
        next: (res) => {
          console.log(res);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  candidateSetSubscription(subscriber: SubscriptionStripePost) {
    return this.http.post<PaymentResponseAllSecure>(
      `${environment.API_JOOBZZ}${environment.API_POST_STRIPE_TOKEN}`,
      subscriber,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }).set('content-type', 'application/json'),
      }
    );
    // .pipe(retry({ count: 2, delay: 700 }));
  }

  // candidateUnsubscribe(subscriptionId: number) {
  //   return this.http
  //     .post(
  //       `${environment.API_JOOBZZ}${environment.API_POST_UNSUBSCRIBE}`,
  //       subscriptionId,
  //       { responseType: 'text' }
  //     )
  //     .pipe(retry({ count: 2, delay: 700 }));
  // }

  candidateGetPaymentSuccessInfo(purchaseId: string, uuid: string) {
    const params = new HttpParams()
      .set('uuid', uuid)
      .set('purchaseId', purchaseId);
    return this.http
      .get<PaymentSuccessDetailsResult>(
        `${environment.API_JOOBZZ}${environment.API_GET_CANDIDATE_PAYMENT_SUCCESS_DETAILS}`,
        { params }
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  candidateGetPaymentErrorInfo(purchaseId: string, uuid: string) {
    const params = new HttpParams()
      .set('uuid', uuid)
      .set('purchaseId', purchaseId);
    return this.http
      .get<PaymentErrorDetailsResult>(
        `${environment.API_JOOBZZ}${environment.API_GET_CANDIDATE_PAYMENT_ERROR}`,
        { params }
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  candidateCheckSubscription(subId: number) {
    const params = new HttpParams().set('candidateSubscriptionId', subId);

    return this.http
      .get<IsCanceledSubscription>(
        `${environment.API_JOOBZZ}${environment.API_GET_IS_SUBSCRIPTION_CANCELED_CHECK}`,
        { params }
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  candidateGetCouponInfo(couponId: string, subscriptionPlanTypeId: number) {
    const params = new HttpParams()
      .set('couponId', couponId)
      .set('subscriptionPlanTypeId', subscriptionPlanTypeId);

    return this.http
      .get<CouponInfo>(
        `${environment.API_JOOBZZ}${environment.API_GET_COUPON_INFO}`,
        { params }
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  candidateSetProfileStatus(profileStatus: 1 | 2) {
    const obj: CandidateProfileStatus = {
      candidateId: Number(localStorage.getItem('candidateId')),
      profileStatusId: profileStatus,
    };
    return this.http
      .put<CandidateProfileStatus>(
        `${environment.API_JOOBZZ}${environment.API_PUT_CANDIDATE_PROFILE_STATUS}`,
        obj
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  deleteCandidate(candidateId?: number, userEmail?: string, userId?: string) {
    if (typeof candidateId === 'undefined') {
      candidateId = Number(localStorage.getItem('candidateId'));
    }

    if (typeof userEmail === 'undefined') {
      userEmail = localStorage.getItem('userEmail') || '';
    }

    if (typeof userId === 'undefined') {
      userId = localStorage.getItem('userId') || '';
    }

    return this.http
      .delete(
        `${environment.API_JOOBZZ}${environment.API_DELETE_CANDIDATE}/${candidateId}`
      )
      .pipe(
        switchMap((res) => {
          console.log(res);
          return this.http.post(
            `${environment.API_JOOBZZ}${environment.API_POST_REMOVE_USER}`,
            `"${userEmail}"`,
            {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
              }),
            }
          );
        })
      )
      .pipe(retry({ count: 2, delay: 700 }));

    // return this._getCandidateAllData(userId)
    //   .pipe(
    //     switchMap((res) => {
    //       const activeSub = res.candidateSubscriptions.filter((sub) => {
    //         return sub.subscriptionPlanTypeId !== 4;
    //       });

    //       if (activeSub.length > 0) {
    //         return this.candidateUnsubscribe(
    //           activeSub[0].candidateSubscriptionId
    //         );
    //       }
    //       return of(true);
    //     })
    //   )
    //   .pipe(
    //     switchMap((res) => {
    //       console.log(res);
    //       return this.http
    //         .delete(
    //           `${environment.API_JOOBZZ}${environment.API_DELETE_CANDIDATE}/${candidateId}`
    //         )
    //         .pipe(
    //           switchMap((res) => {
    //             console.log(res);
    //             return this.http.post(
    //               `${environment.API_JOOBZZ}${environment.API_POST_REMOVE_USER}`,
    //               `"${userEmail}"`,
    //               {
    //                 headers: new HttpHeaders({
    //                   'Content-Type': 'application/json',
    //                 }),
    //               }
    //             );
    //           })
    //         )
    //         .pipe(retry({ count: 2, delay: 700 }));
    //     })
    //   );
  }

  getMonthlyStatistics() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<Statistics>(
        `${environment.API_JOOBZZ}${environment.API_GET_CANDIDATE_MONTHLY_STATISTICS}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.candidateMonthlyStatistics.next(res);
        },
      });
  }

  getHalfMonthStatistics() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<Statistics>(
        `${environment.API_JOOBZZ}${environment.API_GET_CANDIDATE_HALF_MONTH_STATISTICS}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.candidateHalfMonthStatistics.next(res);
        },
      });
  }
  isCandidate() {
    const role = localStorage.getItem('userRole');
    if (role === 'Candidate') {
      return true;
    } else {
      return false;
    }
  }
}
