import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DropdownDataService } from 'src/app/services/dropdown-data.service';
import { LoginService } from 'src/app/services/login.service';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { ResetPasswordRequest } from 'src/app/interfaces/reset-password-request.model';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-reset-pass-dialog',
  templateUrl: './reset-pass-dialog.component.html',
  styleUrls: ['./reset-pass-dialog.component.scss'],
})
export class ResetPassDialogComponent {
  isSettingNewPass = false;
  resetPass = this.formBuilder.group({
    email: ['', [Validators.required]],
  });

  get email() {
    return this.resetPass.controls.email;
  }
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private dialogRef: MatDialogRef<LoginDialogComponent>,
    private router: Router,
    private dropDownDataService: DropdownDataService,
    private _snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  onChangePassword() {
    this.isSettingNewPass = true;
    const data = {} as ResetPasswordRequest;
    const userName = this.resetPass.value.email as string;
    data.email = userName;
    data.languageId = 0;

    this.loginService
      .resetPassword(data)
      .pipe(
        finalize(() => {
          this.isSettingNewPass = false;
        })
      )
      .subscribe({
        next: (res) => {
          // console.log(res);
          this._snackBar.open(
            this.translate.instant('home.resetPassDialog.resetSucces'),
            'OK',
            {
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: 'custom-snackbar',
            }
          );

          this.dialogRef.close();
        },
        error: (err) => {
          // console.log(err);
          this._snackBar.open(
            this.translate.instant('home.resetPassDialog.resetErr'),
            'OK',
            {
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: 'custom-snackbar',
            }
          );
        },
      });
  }
}

export function openResetPassDialog(dialog: MatDialog) {
  const config = new MatDialogConfig();
  config.disableClose = true;
  config.autoFocus = true;
  config.maxWidth = '573px';
  config.maxHeight = '555px';
  config.backdropClass = 'customDialogBackdrop';
  config.panelClass = ['customDialogOverlay', 'loginOverlay'];

  const dialogRef = dialog.open(ResetPassDialogComponent, config);

  return dialogRef.afterClosed();
}
