import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter, map } from 'rxjs';
import { singnUpDialog } from '../components/sign-up-dialog/sign-up-dialog.component';
import { openLoginDialog } from '../components/login-dialog/login-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  userLogedIn = false;
  url = '';
  subscriptionRouter: Subscription = new Subscription();
  constructor(private dialog: MatDialog, private router: Router) {}
  ngOnInit(): void {
    // console.log('user', this.userLogedIn, localStorage.getItem('userRole'));
    this.subscriptionRouter = this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        map((e) => {
          // e is now NavigationEnd
          return e;
        })
      )
      .subscribe((res) => {
        this.url = this.router.url;

        if (localStorage.getItem('userRole') !== null) {
          this.userLogedIn = true;
        } else {
          this.userLogedIn = false;
        }
      });
  }

  onSignUp() {
    singnUpDialog(this.dialog);
  }

  onLogin() {
    openLoginDialog(this.dialog);
  }

  ngOnDestroy(): void {
    this.subscriptionRouter.unsubscribe();
  }
}
