import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { openLoginDialog } from '../components/login-dialog/login-dialog.component';
import { singnUpDialog } from '../components/sign-up-dialog/sign-up-dialog.component';
import { Subscription, fromEvent } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'sticky top-0 bg-white z-50';
  scrollListener: Subscription = new Subscription();
  isMenuShown = '-translate-x-full';
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.scrollListener = fromEvent(window, 'scroll').subscribe((e) => {
      this.onWindowScroll();
    });
  }

  onWindowScroll() {
    if (window.pageYOffset > 0) {
      // this.classes = 'sticky top-0 bg-white z-50 cloud-shadow';
      this.classes = 'sticky top-0 bg-white z-50 header-shadow';
    } else {
      this.classes = 'sticky top-0 bg-white z-50';
    }
  }

  onMenuClicked() {
    if (this.isMenuShown === '-translate-x-full') {
      this.isMenuShown = '';
    } else {
      this.isMenuShown = '-translate-x-full';
    }
  }

  onLogin() {
    this.closeMenu();
    openLoginDialog(this.dialog);
  }

  onSignup() {
    this.closeMenu();
    singnUpDialog(this.dialog);
  }
  closeMenu() {
    this.isMenuShown = '-translate-x-full';
  }

  ngOnDestroy(): void {
    this.scrollListener.unsubscribe();
  }
}
