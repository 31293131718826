import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { MatSelectModule } from '@angular/material/select';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LangSelectComponent } from './header/lang-select/lang-select.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { MatDialogModule } from '@angular/material/dialog';
import { register } from 'swiper/element/bundle';
import { ClientHeaderComponent } from './client-header/client-header.component';
import { CompanyHeaderComponent } from './company-header/company-header.component';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MomentDateModule,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { LangInterceptor } from './interceptors/lang.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxStripeModule } from 'ngx-stripe';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RefreshTokenInterceptor } from './interceptors/refresh-token.interceptor';
import { LoginService } from './services/login.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
register();
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LangSelectComponent,
    ClientHeaderComponent,
    CompanyHeaderComponent,
    AdminHeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    HttpClientModule,
    MatNativeDateModule,
    MatDialogModule,
    MomentDateModule,
    MatMomentDateModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatTooltipModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-W6KB6G2X',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
  ],
  exports: [LangSelectComponent],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
