import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyPostData } from '../interfaces/company-post-data.model';
import { environment } from 'src/environments/environment';
import { Recruiter } from '../interfaces/recruiter.model';
import { Company } from '../interfaces/company.model';
import { BehaviorSubject, retry, retryWhen, switchMap } from 'rxjs';
import { RecruiterOverview } from '../interfaces/recruiter-overview.model';
import { SearchPost } from '../interfaces/search-post.model';
import { SearchResultCandidadteList } from '../interfaces/search-result-candidadte-list.model';
import { RecruiterConfirmEmail } from '../interfaces/recruiter-confirm-email.model';
import { Statistics } from '../interfaces/statistics.model';
import { RecruiterActivities } from '../interfaces/recruiter-activities.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyInfoService {
  private recruiterAllData = new BehaviorSubject<CompanyPostData>(
    {} as CompanyPostData
  );
  private recruiterProfileCompleteness = new BehaviorSubject<number>(0);
  private recruiterOverview = new BehaviorSubject<RecruiterOverview>(
    {} as RecruiterOverview
  );

  private recruiterSearchResult =
    new BehaviorSubject<SearchResultCandidadteList>(
      {} as SearchResultCandidadteList
    );

  private lastSearch = new BehaviorSubject<SearchPost>({} as SearchPost);

  private recruiterMonthlyStatistics = new BehaviorSubject<Statistics>(
    {} as Statistics
  );

  private recruiterHalfMonthStatistics = new BehaviorSubject<Statistics>(
    {} as Statistics
  );

  recruiterAllData$ = this.recruiterAllData.asObservable();
  recruiterProfileCompleteness$ =
    this.recruiterProfileCompleteness.asObservable();
  recruiterOverview$ = this.recruiterOverview.asObservable();
  recruiterSearchResult$ = this.recruiterSearchResult.asObservable();
  recruiterMonthlyStatistics$ = this.recruiterMonthlyStatistics.asObservable();
  recruiterHalfMonthStatistics$ =
    this.recruiterHalfMonthStatistics.asObservable();
  lastSearch$ = this.lastSearch.asObservable();

  constructor(private http: HttpClient) {}

  saveAll(data: CompanyPostData) {
    return this.http.post<CompanyPostData>(
      `${environment.API_JOOBZZ}${environment.API_POST_COMPANY}`,
      data
    );
  }

  getRecruiterData() {
    const id = localStorage.getItem('recruiterId');
    return this.http
      .get<Recruiter>(
        `${environment.API_JOOBZZ}${environment.API_GET_RECRUITER}/${id}`
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  getCompanyData() {
    const id = localStorage.getItem('companyId');
    return this.http
      .get<Company>(`${environment.API_JOOBZZ}${environment.API_GET_COMPANY}/5`)
      .pipe(retry({ count: 2, delay: 700 }));
  }

  getRecruiterAllData() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<CompanyPostData>(
        `${environment.API_JOOBZZ}${environment.API_GET_RECRUITER_COMPANY_DATA}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe((res) => {
        this.recruiterAllData.next(res);
      });
  }

  getRecruiterExteranlAllData(userId: string) {
    return this.http
      .get<CompanyPostData>(
        `${environment.API_JOOBZZ}${environment.API_GET_RECRUITER_COMPANY_DATA}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe((res) => {
        this.recruiterAllData.next(res);
      });
  }

  getRecruiterProfileCompleteness() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<number>(
        `${environment.API_JOOBZZ}${environment.API_GET_RECRUITER_COMPLEATNES}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.recruiterProfileCompleteness.next(res);
        },
      });
  }

  updateRecruiterData(data: Recruiter) {
    return this.http.put(
      `${environment.API_JOOBZZ}${environment.API_PUT_UPDATE_RECRUITER_DATA}/${data.recruiterPersonalInformationId}`,
      data
    );
  }

  updateCompanyData(data: Company) {
    return this.http.put(
      `${environment.API_JOOBZZ}${environment.API_PUT_UPDATE_COMPANY_DATA}/${data.companyId}`,
      data
    );
  }

  getRecruiterOverview() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<RecruiterOverview>(
        `${environment.API_JOOBZZ}${environment.API_GET_RECRUITER_OVERVIEW}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.recruiterOverview.next(res);
        },
      });
  }

  saveSearch(search: SearchPost) {
    return this.http.post<SearchPost>(
      `${environment.API_JOOBZZ}${environment.API_POST_POST_COMPANY_SEARCH}`,
      search
    );
  }

  updateSearch(search: SearchPost, searchId: number) {
    return this.http.put<SearchPost>(
      `${environment.API_JOOBZZ}${environment.API_PUT_SEARCH_UPDATE}/${searchId}`,
      search
    );
  }

  getSearch(searchId: number, pageIndex: number, pageSize: number) {
    const params = new HttpParams()
      .set('searchId', searchId)
      .set('pageIndex', pageIndex)
      .set('pageSize', pageSize);

    return this.http
      .get<SearchResultCandidadteList>(
        `${environment.API_JOOBZZ}${environment.API_GET_COMPANY_SEARCH_RESULT}`,
        { params }
      )
      .subscribe({
        next: (res) => {
          this.recruiterSearchResult.next(res);
        },
      });
  }

  getLastSearch() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<SearchPost>(
        `${environment.API_JOOBZZ}${environment.API_GET_LAST_SEARCH}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.lastSearch.next(res);
        },
      });
  }

  sendViewRequest(candidateId: number, recruiterId: number) {
    const params = new HttpParams()
      .set('candidateId', candidateId)
      .set('recruiterId', recruiterId);

    return this.http
      .get<SearchResultCandidadteList>(
        `${environment.API_JOOBZZ}${environment.API_GET_COMPANY_SEND_VIEW_REQUEST}`,
        { params }
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  deleteRecruiter() {
    const recruiterId = Number(localStorage.getItem('recruiterId'));
    const userEmail = localStorage.getItem('userEmail');
    return this.http
      .delete(
        `${environment.API_JOOBZZ}${environment.API_DELETE_RECRUITER}/${recruiterId}`
      )
      .pipe(
        switchMap((res) => {
          return this.http.post(
            `${environment.API_JOOBZZ}${environment.API_POST_REMOVE_USER}`,
            `"${userEmail}"`,
            {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
              }),
            }
          );
        })
      );
  }

  sendRecruiterConfirmEMail() {
    return this.http
      .post<RecruiterConfirmEmail>(
        `${environment.API_JOOBZZ}${environment.API_POST_CONFIRM_RECRUITER_EMAIL}`,
        {
          userId: localStorage.getItem('userId') || '',
          type: 0,
          languageId: 0,
        }
      )
      .subscribe();
  }

  downloadResume(userId: string) {
    return this.http
      .get(
        `${environment.API_JOOBZZ}${environment.API_GET_CANDIDATE_CV_PDF}/${userId}`,
        {
          responseType: 'blob' as 'json',
          observe: 'response',
        }
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  getMonthlyStatistics() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<Statistics>(
        `${environment.API_JOOBZZ}${environment.API_GET_RECRUITER_MONTHLY_STATISTICS}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.recruiterMonthlyStatistics.next(res);
        },
      });
  }

  getHalfMonthStatistics() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<Statistics>(
        `${environment.API_JOOBZZ}${environment.API_GET_RECRUITER_HALF_MONTH_STATISTICS}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.recruiterHalfMonthStatistics.next(res);
        },
      });
  }

  getRecruterApprovedState() {
    const userId = localStorage.getItem('userId');
    const params = new HttpParams().set('userId', userId as string);

    return this.http
      .get(
        `${environment.API_JOOBZZ}${environment.API_GET_RECRUITER_APPROVED_STATE}`,
        { params }
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  isRecruiter() {
    const role = localStorage.getItem('userRole');
    if (role === 'Company') {
      return true;
    } else {
      return false;
    }
  }
}
